import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-aviso-geral',
  templateUrl: './modal-aviso-geral.component.html',
  styleUrls: ['./modal-aviso-geral.component.scss']
})
export class ModalAvisoGeralComponent{

  @Input() pageId;
  @Input() nameButton: string = 'Voltar';
  @Input() isOneButton: boolean = true;
  @Input() nameButtonConfirm: string = 'Sim';
  @Input() nameButtonCancel: string = 'Não';
  @Input() titleMsg: string = 'Aviso';
  @Output() buttonConfirmSubmit = new EventEmitter();
  @Output() buttonCancelSubmit = new EventEmitter();
  public title: string;
  public message: string;

  constructor(
    public activeModal: NgbActiveModal,
    public router: Router
  ) { }

  public closeModal() {
    this.buttonConfirmSubmit.emit(false);
    this.activeModal.close();
  }
  public submit() {
    this.buttonConfirmSubmit.emit(true);
    this.activeModal.close({submit: true});
  }

}
