import Swal from 'sweetalert2';

import { SweetAlertType } from '../types/sweet-alert.type';
import { ExclusaoRegistroDto } from '@core/dto/ExclusaoRegistro/exclusao-registro-dto';

export class SweetalertCustom {

    constructor() { }

    /**
     * Função para exibir o alerta pelo tempo
     * @param titleAlert Titulo que vai ser exibido no alerta
     * @param typeAlert Qual o tipo do alerta que deseja exibir
     * @param timerAlert Quanto tempo deseja exibir caso não queira o padrão
     */
     static showAlertTimer(
      typeAlert: SweetAlertType,
      titleAlert: string,
      textAlert?: string,
      timerAlert = 2000) {
      return Swal.fire({
          title: titleAlert,
          html: textAlert,
          icon: typeAlert,
          showConfirmButton: false,
          timer: timerAlert,
          allowOutsideClick: false,
      });
  }


    /**
     * Função para exibir o alerta com apenas confirmar
     * @param titleAlert Titulo que vai ser exibido no alerta
     * @param typeAlert Qual o tipo do alerta que deseja exibir
     * @param confirmButtonTxt Texto para ser exibido no Botão caso não queira o padrão
     * @param textAlert Texto complementar caso queira exibir
     */

    static showAlertConfirm(
        titleAlert: string,
        typeAlert: SweetAlertType | any ,
        confirmButtonTxt: string = 'Ok',
        textAlert?: string) {

        if (Array.isArray(titleAlert) && titleAlert.length){
            textAlert = '<ul class="text-left">';
            titleAlert.map(err => textAlert += `<li> ${err} </li>`);
            textAlert += '</ul>';
            titleAlert = 'Atenção!';
        }

        return Swal.fire({
            title: titleAlert,
            html: textAlert,
            icon: typeAlert.type,
            confirmButtonText: confirmButtonTxt,
            allowOutsideClick: false,
        });
    }

    /**
     * Função para exibir o alerta com apenas confirmar
     * @param typeAlert Qual o tipo do alerta que deseja exibir
     * @param titleAlert Titulo que vai ser exibido no alerta
     * @param textAlert Texto complementar caso queira exibir
     * @param confirmButtonTxt Texto para ser exibido no Botão caso não queira o padrão
     */
     static newShowAlertConfirm(
      typeAlert: SweetAlertType,
      titleAlert: string,
      textAlert?: string,
      confirmButtonTxt: string = 'Ok',
      customClass?: string
      ) {
      return Swal.fire({
          title: titleAlert,
          html: textAlert,
          icon: typeAlert,
          confirmButtonText: confirmButtonTxt,
          allowOutsideClick: false,
          customClass: {
              content: customClass
            }
      });
  }

    /**
     * Função para Exibir o alerta com confirmar e cancelar
     * @param titleAlert Titulo que vai ser exibido no alerta
     * @param typeAlert  Qual o tipo do alerta que deseja exibir
     * @param confirmButtonTxt Texto para ser exibido no Botão caso não queira o padrão
     * @param cancelButtonTxt Texto para ser exibido no Botão caso não queira o padrão
     * @param textAlert Texto complementar caso queira exibir
     */
    static showAlertConfirmAndCancel(
        titleAlert: string,
        typeAlert: SweetAlertType,
        textAlert?: string,
        confirmButtonTxt: string = 'Sim',
        cancelButtonTxt: string = 'Não') {
        return Swal.fire({
            title: titleAlert,
            html: textAlert,
            icon: typeAlert,
            showCancelButton: true,
            confirmButtonColor: '#006A46',
            cancelButtonColor: '#DE2021',
            confirmButtonText: confirmButtonTxt,
            cancelButtonText: cancelButtonTxt,
            allowOutsideClick: false,
        });
    }

    static showAlertConfirmAndCancelV2(
        titleAlert: string,
        typeAlert: SweetAlertType,
        textAlert?: string,
        confirmButtonTxt: string = 'Sim',
        cancelButtonTxt: string = 'Não'
      ) {

        return Swal.fire({
          title: titleAlert,
          html: textAlert,
          icon: typeAlert,
          showCancelButton: true,
          confirmButtonText: confirmButtonTxt,
          cancelButtonText: cancelButtonTxt,
          allowOutsideClick: false,
          customClass: {
            confirmButton: 'btn btn-sm rounded-pill btn-primary',
            cancelButton: 'btn btn-sm rounded-pill btn-danger'
          }
        });
      }
      /**
     * Função para exibir o alerta de exclusão de registro com relacionamento
     * @param tabelasRelacionadas Tabelas com relacionamento
     * @param confirmButtonTxt Texto para ser exibido no Botão caso não queira o padrão
     */
    static ShowAlertConfirmExclusaoByRelacionamento(
      tabelasRelacionadas: Array<ExclusaoRegistroDto>,
      confirmButtonTxt: string = 'OK',
  ) {
      return Swal.fire({
          title: `${tabelasRelacionadas.length > 1 ? 'Locais' : 'Local'} em que o registro selecionado está em uso:`,
          html: `<div class="text-justify">${tabelasRelacionadas.map(x => '<li>' + x.foreignSchemaTable + '</li>').join('')}</div> <br> <strong>Total de tabelas em que o registro está em uso: ${tabelasRelacionadas.length}</strong>`,
          icon: 'warning',
          confirmButtonText: confirmButtonTxt,
          allowOutsideClick: false,
          width: 800
      });
  }

  static showAlertQuestion(
    typeAlert: SweetAlertType,
    titleAlert: string,
    textAlert?: string,
    confirmButtonTxt = 'Sim',
    cancelButtonTxt = 'Não') {
    return Swal.fire({
        title: titleAlert,
        html: textAlert,
        icon: typeAlert,
        showCancelButton: true,
        confirmButtonColor: '#006A46',
        cancelButtonColor: '#DE2021',
        confirmButtonText: confirmButtonTxt,
        cancelButtonText: cancelButtonTxt,
        allowOutsideClick: false,
    });
  }  
}
