export const APP_VERSION = {
    version: '1.6.0',
    data: '21/08/2024'
}

export const MSG_SUCCES = 'Operação realizada com sucesso!';
export const MSG_SUCCES_ORGAO = 'Cadastro concluído com sucesso.';
export const MSG_FALIELD = 'Falha na operação.';
export const MSG_QUESTION_DELETE = 'Deseja Excluir';
export const MSG_DELETE = 'Você tem certeza que deseja excluir este registro?';
export const MSG_DELETE_IRREVERSIVEL = 'Deseja realmente realizar  a exclusão deste registro? Essa ação é irreversível.';
export const MSG_CREATE_SUCCCESS = 'Registro cadastrado com sucesso.';
export const MSG_UPDATE_SUCCCESS = 'Registro atualizado com sucesso.';
export const MSG_CREATE_SUCCESS = 'Registro cadastrado com sucesso!';
export const MSG_UPDATE_SUCCESS = 'Registro atualizado com sucesso!';
export const MSG_DELETE_SUCCCESS = 'Registro excluído com sucesso!';
export const MSG_QUESTION_INACTIVATE = 'Deseja Inativar';
export const MSG_INACTIVATE = 'Deseja Inativar este registro';
export const MSG_INACTIVATE_SUCCESS = 'Registro inativado com sucesso!';
export const MSG_DISABLE_SUCCESS = 'Registro desativado com sucesso!';
export const MSG_ACTIVATE_SUCCESS = 'Registro ativado com sucesso!';
export const MSG_ACTIVATE = 'Deseja Ativar este registro';
export const MSG_QUESTION_ACTIVATE = 'Deseja Ativar';
export const MSG_FAIL_PDF = 'Por gentileza anexe um PDF';
export const MSG_UPDATE_TAB_SUCCESS = 'Informações atualizadas com sucesso!';
export const MSG_FAIL_PDF_JPG = 'Por gentileza anexe um arquivo PDF ou JPG.';
export const MSG_SUCCES_UPDATE_TELEFONE_USUARIO = 'Telefone atualizado com sucesso!';
export const MSG_SUCCES_ALTERAR_SENHA_USUARIO = 'Senha alterada com sucesso!';
export const MSG_SUCCESS_RASCUNHO = 'Rascunho salvo com sucesso! Lembre-se: para finalizar a solicitação é necessário finalizar e enviar.';
export const MSG_SUCCESS_AGENDAMENTO_FERIAS = 'Solicitação de agendamento de férias enviada com sucesso! Aguarde a análise do seu Chefe Imediato.';
export const MSG_CONFIRM_APROVAR = 'Tem certeza que deseja aprovar a solicitação de férias?';
export const MSG_CONFIRM_REPROVAR = 'Tem certeza que deseja reprovar a solicitação de férias?';
export const MSG_INATIVAR_TIPO_NOTIFICACAO_EM_USO = 'Você está prestes a marcar este registro como inativo. Por favor, esteja ciente de que esta ação não removerá automaticamente quaisquer vínculos existentes com outros registros.';
export const MSG_INATIVAR_SECRETARIA_EXECUTIVA	 = 'A inativação do registro é irreversível e uma vez inativado o registro não poderá ser reativado.';
export const MSG_ATIVAR_SECRETARIA_EXECUTIVA	 = 'A inativação de registros é uma ação irreversível. Uma vez que um registro é marcado como inativo, essa ação não pode ser desfeita.';
export const MSG_TOOLTIP__BOTAO_TEMPORARIAMENTE_SECRETARIA_EXECUTIVA_CHEFE_IMEDIATO	 = 'Este botão ficará temporariamente bloqueado sempre que o campo “Unidade de Trabalho” estiver vazio ou quando houver Chefia Imediata vinculada.';
export const MSG_BLOQUEIO_BOTAO_TEMPORARIAMENTE_SECRETARIA_EXECUTIVA_CHEFE_IMEDIATO	 = 'Este botão ficará temporariamente bloqueado sempre que o campo “Unidade de Trabalho” estiver vazio ou quando houver Chefia Imediata vinculada.';
export const MSG_TROCA_UTB_SECRETARIA_EXECUTIVA	 = 'Ao trocar o código da Unidade de Trabalho (UTB) o chefe imediato vinculado será removido. Isso ocorre devido à mudança na unidade de trabalho. Deseja continuar?';
export const MSG_TOOLTIP__BOTAO_SEMPRE_SECRETARIA_EXECUTIVA_CRIACAO	 = 'Este botão ficará bloqueado sempre que um documento for anexado.';
export const MSG_TOOLTIP_BOTAO_SEMPRE_SECRETARIA_EXECUTIVA_ATO_NORMATIVO = 'Este botão ficará temporariamente bloqueado sempre que um documento for anexado e será desbloqueado apenas quando houver modificação na Chefia Imediata.';
export const MSG_TOOLTIP_SECRETARIA_EXECUTIVA_CRIACAO_EXCLUSAO = 'Este botão ficará bloqueado sempre que um documento for anexado.';
export const MSG_TOOLTIP_ADD_CHEFE_IMEDIATO = 'Este botão ficará temporariamente bloqueado sempre que o campo “Unidade de Trabalho” estiver vazio ou quando houver Chefia Imediata vinculada.';
export const MSG_TOOLTIP_ADD_NORMATIVA_CRIACAO_EXTINCAO = 'Este botão ficará bloqueado sempre que um documento for anexado.';
export const MSG_TOOLTIP_ADD_ATO_GOVERNAMENTAL = 'Este botão ficará temporariamente bloqueado sempre que um documento for anexado e será desbloqueado apenas quando houver modificação na Chefia Imediata.';
export const MSG_QUESTION_TROCA_UTB = 'Ao trocar o código da Unidade de Trabalho (UTB) o chefe imediato vinculado será removido. Isso ocorre devido à mudança na unidade de trabalho. Deseja continuar?';
export const MSG_QUESTION_TROCA_UTB_ATO = 'Ao trocar o código da Unidade de Trabalho (UTB) o chefe imediato e o ato governamental anexado serão removidos. Isso ocorre devido à mudança na unidade de trabalho. Deseja continuar?';
export const MSG_QUESTION_TROCA_UTB_E_ATO_GOVERNAMENTAL = 'Ao mudar o chefe imediato, é necessário anexar um novo ato governamental. Deseja continuar?';
export const MSG_SUCCESS_CAD_SECRETARIA_GERAL = 'Secretaria Geral cadastrada com sucesso!';
export const MSG_SUCCESS_UPDATE_SECRETARIA_GERAL = 'Secretaria Geral atualizada com sucesso!';
export const MSG_TOOLTIP_ADD_SECRETARIA_GERAL = 'Só será possível criar uma nova Secretaria Geral quando todos os registros existentes na listagem estiverem inativos.';
export const MSG_SUCCESS_ADD_GERENCIA_EXECUTIVA = 'Gerência Executiva cadastrada com sucesso!';
export const MSG_SUCCESS_ADD_SUBGERENCIA = 'Subgerência cadastrada com sucesso!';
export const MSG_SUCCESS_UPDATE_GERENCIA_EXECUTIVA = 'Gerência Executiva atualizada com sucesso!';
export const MSG_SUCCESS_UPDATE_SUBGERENCIA = 'Subgerência atualizada com sucesso!';
export const MSG_INFO_INACTIVATE = 'A inativação de registros é uma ação irreversível. Uma vez que um registro é marcado como inativo, essa ação não pode ser desfeita.';
export const MSG_SUCCESS_UPDATE_SECRETARIA_EXECUTIVA = 'Secretaria Executiva atualizada com sucesso!';
export const MSG_REVISAO_PGDI_MAIOR_IGUAL_12 = 'Verificamos que a pontuação do PGDI deste Servidor está na média ou acima dela. Isso significa que, ao concluir o PGDI, o processo será finalizado. Deseja continuar?';
export const MSG_REVISAO_PGDI_MENOR_12 = 'Este PGDI está com nota abaixo de 12. Ao concluir esta etapa do processo o PGDI será recusado e enviado para comissão de avaliação. Deseja continuar?';
export const MSG_INATIVAR_GERENCIA_EXECUTIVA	 = 'A inativação do registro é irreversível e uma vez inativado o registro não poderá ser reativado.';
export const MSG_CRIACAO_PGDI_PERIODO_AVALIATIVO_SEM_VIGENCIA = 'Este PGDI não pode ser criado no momento pois seu período avaliativo ainda não está em vigência.'
export const MSG_PERMISSAO_PGDI = 'Não é permitido criar PGDI para o período selecionado. Favor selecionar o período anterior.'
export const MSG_LIDANDO_COM_DADOS_PARECER_CONCLUSIVO = 'Desculpe. Não conseguimos listar os dados desse Parecer Conclusivo, tente novamente em um outro momento.'
export const MSG_SEM_ACESSO_INFORMACOES_DO_SISTEMA = 'Infelizmente não foi possível acessar as informações do sistema nesse momento. Tente acessar mais tarde.'
export const MSG_SERVIDOR_COM_PGDI = 'Este Servidor já possui todos os PGDI’s que compõe sua avaliação de desempenho. Por isso não é mais possível criar PGDI para esse Servidor.'
export const MSG_MEMBRO_JA_EXISTENTE = 'Este membro já existe na lista.'
export const MSG_COMISSAO_3_A_5_MEMBROS = 'A comissão deve ser formada por no mínimo 3 e no máximo 5 membros.'
export const MSG_NAO_PODE_ADICIONAR_CHEFE_IMEDIATO = 'Não é possível adicionar esse usuário à comissão pois ele é chefe imediato.'
export const MSG_CAMPOS_OBRIGATORIOS = 'Todos os campos obrigatórios precisam ser preenchidos corretamente.'
export const MSG_NAO_EXISTE_PERIODO_FERIAS = 'Não existe período de férias disponível para agendamento.'
export const MSG_POSSUI_SOLICITACAO_FERIAS = 'Você possui uma solicitação de férias em andamento. Aguarde o retorno das férias para solicitar um novo agendamento.'
export const MSG_PARECER_CONCLUSIVO_SUCCESS = 'Parecer Conclusivo criado com sucesso!'
export const MSG_PARECER_CONCLUSIVO_UPDATE = 'Parecer Conclusivo atualizado com sucesso!'
export const MSG_PARECER_CONCLUSIVO_JA_CRIADO = 'Parecer Conclusivo do Servidor já foi criado.'
export const MSG_PARECER_CONCLUSIVO_VALIDADO = 'Parecer Conclusivo validado com sucesso!'
export const MSG_CAMPOS_FREQUENCIA_OBRIGATORIOS = 'Todos os campos de frequência são obrigatórios.'
export const MSG_AVALIACAO_DEVOLVIDA_PARA_GR = 'Avaliação Devolvida para o Gestor Responsável.'
export const MSG_ENVIO_NOTIFICACAO_AOS_ENVOLVIDOS_NO_PROCESSO = 'O sistema enviará uma notificação aos envolvidos no processo, informando sobre a mudança de status.'
export const MSG_ALOCAR_PROFESSOR = 'Só é possível alocar um(a) professor(a).'
export const MSG_ALTERACOES_NAO_SALVAR_PGDI = 'Você possui alterações que não foram salvas no PGDI, deseja salvar antes de voltar?'
export const MSG_PGDI_CRIACAO_NAO_PODE_ALTERAR = 'Após criado as informações não poderão ser alteradas. Deseja realmente continuar?'
export const MSG_PGDI_SUCCESS = 'PGDI Criado com Sucesso!'
export const MSG_PGDI_REVISADO_VALIDADO = 'PGDI Revisado e Validado com Sucesso!'
export const MSG_NAO_SEM_RETORNO_LISTAGEM_TERMO_AVALIACAO = 'Desculpe. Não conseguimos listar os dados desse Termo de Avaliação, tente novamente em um outro momento.'
export const MSG_TERMO_AVALIACAO_VALIDADO_COM_SUCESSO = 'Termo de Avaliação de Desempenho Validado com Sucesso!'
export const MSG_RESTRICAO_JA_EXISTE = 'Essa restrição já existe na lista.'
export const MSG_CONFIRMACAO_REMOCAO_RESTRICAO = 'Confirma a remoção da restrição:'
export const MSG_CADASTRO_TIPO_NOTIFICACAO_SUCCESS = 'Tipo de Notificação cadastrado com sucesso!'
export const MSG_CADASTRO_TIPO_NOTIFICACAO_UPDATE = 'Tipo de Notificação atualizado com sucesso!'
export const MSG_OBRIGATORIEDADE_GERAR_RESENHA_UMA_SOLICITACAO_FERIAS = 'Para gerar Resenha é obrigatório selecionar ao menos um registro de solicitação de férias.'
export const MSG_DESEJA_GERAR_RESENHA = 'Deseja realmente gerar Resenha com o(s)'
export const MSG_DESEJA_GERAR_RESENHA_ITEM_SELECIONADO = 'item(ns) selecionado(s)?'
export const MSG_NENHUM_REGISTRO_ENCONTRADO = 'Nenhum registro encontrado.'
export const MSG_NENHUM_VINCULO_ENCONTRADO = 'Nenhum vínculo encontrado.'
export const MSG_INATIVAR_GERENCIA_REGIONAL = 'A inativação de registros é uma ação irreversível. Uma vez que um registro é marcado como inativo, essa ação não pode ser desfeita.';
export const MSG_INATIVAR_TODAS_VERIFICACOES_GERENCIA_REGIONAL = 'Para inativar um registro, todas as verificações devem ser atendidas e a normativa de extinção deve ser anexada.';
export const MSG_DELETE_SEAD = 'Deseja realmente realizar a exclusão deste registro?';
export const MSG_IMPORTACAO_REALIZADA = 'Nova importação realizada com sucesso! Sistema em processamento da carga.';
export const MSG_IMPORTACAO_PROCESSAMENTO = 'Não é possível nova carga enquanto existir importação de e-mails em processamento.';
export const MSG_CAMPOS_OBRIGATORIOS_SEAD= 'Todos os campos obrigatórios precisam ser preenchidos corretamente.'
export const MSG_IMPORTACAO_PROFISSIONAL = 'Nova importação adicionada com sucesso!'
export const MSG_TOOLTIP__UTB_BLOCK	 = 'Este campo ficará bloqueado sempre que o sistema verificar que existe Servidor vinculado a Chefia Imediata e/ou vinculação da Subgerência cadastrada com outros registros do Módulo de Configuração.';
export const MSG_INATIVAR_SUB_GERENCIA = 'A inativação do registro é irreversível e uma vez inativado o registro não poderá ser reativado.';
export const MSG_IMPOSSIBILIDADE_INATIVACAO_LIST = `Não é possível realizar a inativação do registro. Para seguir com a inativação você deverá:<br/><br/><ol style="text-align: left;">`
export const MSG_REQUIRED_FIELDS = 'Todos os campos obrigatórios precisam ser preenchidos corretamente.';
export const MSG_TOOLTIP_ADD_UTB_GERENCIA_REGIONAL = 'Este campo ficará bloqueado sempre que o sistema verificar que existe Servidor vinculado a Chefia Imediata e /ou vinculação da Gerência Regional cadastrada com outros registros do Módulo de Configuração';
export const MSG_IMPOSSIBILIDADE_INATIVACAO_GERENCIA_REGIONAL = 'Não é possível realizar a inativação do registro. Para seguir com a inativação você deverá:'
export const MSG_IMPOSSIBILIDADE_INATIVACAO = 'Não é possível realizar a inativação do registro. Para seguir com a inativação você deverá:'
export const MSG_TOOLTIP_ADD_UTB_NUCLEO = 'Este campo ficará bloqueado sempre que o sistema verificar que existe Servidor vinculado a Chefia Imediata';
export const MSG_SUCCES_GERENCIA_REGIONAL_CADASTRO = 'Gerência Regional cadastrada com sucesso!';
export const MSG_SUCCES_GERENCIA_REGIONAL_ATUALIZACAO = 'Gerência Regional atualizada com sucesso!';
