import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ArquivoCreateDto } from '@core/dto/arquivo/arquivo-create-dto';
import { ArquivoService } from '@core/services/arquivo.service';
import { Util } from 'src/app/shared/utils/util';
import { FormBase } from '../../utils/form-base';
import { SweetalertCustom } from '../../utils/sweetalert-custom';

@Component({
  selector: 'app-upload-one-file-v2',
  templateUrl: './upload-one-file-v2.component.html',
  styleUrls: ['./upload-one-file-v2.component.scss']
})
export class UploadOneFileV2Component extends FormBase {

  @Input() editable: boolean = true;
  @Input() accept = '.pdf';
  @Input() form: FormGroup = new FormGroup({});
  @Input() name = '';
  @Input() canDownload = true;
  @Input() mdButton = false;
  @Input() isFileEdit: boolean;
  @Input() fileStream = false;
  @Input() validationFileType = 'acceptedTypes';
  @ViewChild('file') inputRef!: any;
  @Input() maxSize = 10; // In Mb
  @Input() acceptedTypes = ['jpeg', 'jpg', 'png', 'pdf'];
  @Input() buttonName = 'Anexar Arquivo ';
  @Input() exibirExtensaoNoBotao: boolean = true;
  @Input() exibirNomeAnexo: boolean = true;
  @Input() MsgError = 'O arquivo selecionado não atende aos critérios mínimos exigidos pela aplicação, por favor selecione um outro arquivo!'
  @Output() isEditChanged = new EventEmitter();
  @Output() update = new EventEmitter();
  public file;
  private isTouched: boolean = false;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private arquivoService: ArquivoService) {
    super(router, activatedRoute);
    this.choosePageUpdateOrView();
  }

  get formControl() {
    return this.form?.get(this.name);
  }

  async getBase64FromFile(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve((reader.result as string)?.split(';base64,').pop() ?? '');
      reader.onerror = (error) => reject(error);
    });
  }

  public async onSelectFile(event: Event): Promise<void> {
    if (!this.editable) {
      return;
    }
    this.file = (event.target as HTMLInputElement).files[0];
    this.inputRef.nativeElement.value = '';
    this.form.controls[this.name].setValue(this.file);
    if (this.file) {
      if (!this.isValidFile(this.file)) {
        this.clear();
        return;
      }
      const base64 = await this.getBase64FromFile(this.file);
      const arquivoDto = new ArquivoCreateDto({
        id: this.formControl?.value?.id,
        base64,
        extensao: this.file.type.split('/')[1],
        acao: this.formControl?.value?.id ? 'EDIT' : 'CAD',
        nome: this.file.name.split('.')[0],
      });
      this.formControl?.setValue(arquivoDto);
    }
  }

  isValidFile(file: File): boolean {

    let error = '';
    // Validação do tamanho do arquivo
    if (file.size > this.maxSize * 1024 * 1024) error = `O tamanho do arquivo não pode ser maior que ${this.maxSize}MB`;

    // Validação do tipo do arquivo
    const extension = `${file.name}`.split('.').pop()?.toLocaleLowerCase();

    switch (this.validationFileType) {
      case 'accept':
        if (extension && !this.accept.includes(extension)) error = `O arquivo deve ser do tipo: ${this.accept}`;
        break;
      case 'acceptedTypes':
        if (extension && !this.acceptedTypes.includes(extension)) error = `O arquivo deve ser do tipo: ${this.acceptedTypes.join(', ')}`;
        break;
    }

    //if (extension && !this.acceptedTypes.includes(extension)) error = `O arquivo deve ser do tipo: ${this.acceptedTypes.join(', ')}`;

    if (error) {
      SweetalertCustom.newShowAlertConfirm('warning', 'Atenção!', this.MsgError, 'OK');
      return false;
    }

    return true;
  }

  public clear(): void {
    this.isFileEdit = false;
    this.formControl.setValue(null);
    this.file = null;
    this.inputRef.nativeElement.value = '';
    this.viewAnexo();
  }

  public downloadFile(base64: string, fileName: string): void {
    const downlodedFile = Util.convertBase64ToBlobData(base64, 'application/pdf');
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(downlodedFile);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }


  public downloadFromBack(arquivo: any): void {
    this.arquivoService.download(arquivo.id.toString()).subscribe(res => {
      if (this.fileStream) {
        this.downloadFileByFileStream(res.body, arquivo.nomeOriginal || arquivo.nome);
        return;
      }
      if (res.body.base64) {
        this.downloadFile(res.body.base64, arquivo.nomeOriginal || arquivo.nome);
        return;
      }
      this.downloadFileByFileStream(res.body, arquivo.nomeOriginal || arquivo.nome);
    }, (err) => { });
  }

  public download(): void {
    const arquivo = this.form.controls[this.name].value;
    if (arquivo.id) {
      this.downloadFromBack(arquivo);
      return;
    }
    if (this.fileStream) {
      this.downloadFileByFileStream(arquivo, arquivo.nome);
    }
    this.downloadFile(arquivo.base64, arquivo.nome);
  }

  public viewAnexo(): boolean {

    if (this.file || this.form.get(this.name)?.value) {
      return true;
    }
    return false;
  }

  public downloadFileByFileStream(base64: any, fileName: string): void {
    const downlodedFile = new Blob([base64], { type: 'application/pdf' });
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(downlodedFile);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  setButtonName(accept: any) {
    if (this.exibirExtensaoNoBotao) {
      return `${this.buttonName} ${accept.slice(1)}`
    }

    return this.buttonName;
  }

  markAsTouched() {
    this.isTouched = true;
  }

  get isInvalid() {
    return this.isRequired(this.name) && (this.isTouched || this.formControl?.touched);
  }

}
