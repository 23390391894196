import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AutocompleteDto } from '../dto/auto-complete-dto';

import { URL_CONFIG } from '../utils/url-configs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AutocompleteService {
  private controller = 'Autocomplete';

  constructor(private apiService: ApiService) {}

  public getForSelectFromObject(
    url: string,
    obj?: {}
  ): Observable<any[]> {
    return this.apiService
      .getForSelect(
        `${URL_CONFIG.url_autocomplete}/${url}`,
        this.apiService.getQueryParamsForFilter(obj)
      )
      .pipe(map(response => response.body.data));

  }

  getForSelect(url: string, params?: URLSearchParams) {
    const filters = params ? `?${params.toString()}` : '';
    return this.apiService
      .get(`${this.controller}/${url}${filters}`)
      .pipe(
        map((response) =>
          response.body.data.map((item) => new AutocompleteDto(item))
        )
      );
  }

  public getForMultiselectFromObject(
    url: string,
    obj?: {}
  ): Observable<AutocompleteDto[]> {
    return this.apiService
      .getForSelect(
        `${URL_CONFIG.url_autocomplete}/multiselect/${url}`,
        this.apiService.getQueryParamsForFilter(obj)
      )
      .pipe(
        map((response) =>
          response.body.data.map((data) => new AutocompleteDto(data))
        )
      );
  }


  public getPerfisForSelect(
    params?: URLSearchParams
  ): Observable<AutocompleteDto[]> {
    return this.apiService
      .getForSelect(`${URL_CONFIG.url_autocomplete}/perfil`, params)
      .pipe(
        map((response) =>
          response.body.data.map((data) => new AutocompleteDto(data))
        )
      );
  }

  public getPerfilForSelect(
    params?: URLSearchParams
  ): Observable<AutocompleteDto[]> {
    return this.apiService
      .getForSelect(`${URL_CONFIG.url_autocomplete}/perfil`, params)
      .pipe(
        map((response) =>
          response.body.data.map((data) => new AutocompleteDto(data))
        )
      );
  }

  public getChefeImediatoForSelect(
    params?: URLSearchParams
  ) {
    return this.apiService
      .getForSelect(`${URL_CONFIG.url_autocomplete}/chefe-imediato-secretarias`, params)
      .pipe(map((response) => response.body.data)
      );
  }

  public getTurnoForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/turno`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }  

}



