<p-calendar
  [locale]="locale"
  [id]="name"
  [disabled]="disabled"
  [ngClass]="customClass"
  [(ngModel)]="inputModel"
  [dateFormat]="dateFormat"
  [placeholder]="placeholder"
  [yearNavigator]="true"
  [yearRange]="yearRange"
  [showIcon]="true"
  [showButtonBar]="showButtonBar"
  [view]="view"
  [maxDate]="maxDate"
  [minDate]="minDate"
  (onClearClick)="change()"
  (onSelect)="change()"
  (onBlur)="blur()"
  [formControl]="formControl"
></p-calendar>
