export interface MenuType {
  url?: string;
  label: string;
  exact?: boolean;
  profiles?: string[];
}

export const MENUS_NAVBAR: MenuType[] = [
  {
    url: '',
    label: 'Início',
    exact: true
  },
  {
    url: '/configuracao',
    label: 'Configurações',
  },
  // {
  //   url: '/avaliacao-desempenho',
  //   label: 'Avaliação de Desempenho'
  // },
  // {
  //   url: '/ferias',
  //   label: 'Férias'
  // },
  {
    url: '/profissionais',
    label: 'Profissionais'
  },
  {
    url: '/quadro-funcional',
    label: 'Quadro Funcional'
  }
];
