export const URL_CONFIG = Object.freeze({
  url_autocomplete: 'autocomplete',
  url_perfis: 'perfil',
  url_usuario: 'usuario',
  url_restricoes: 'restricao',
  url_cep: 'cep',
  url_comissoes_avaliacao: 'ComissaoAvaliacao',
  url_avaliacao_desempenho: 'AvaliacaoDesempenho',
  url_comissoes_avaliacao_membros: 'ComissaoAvaliacao/membros/',
  url_ferias: 'ConcessaoFerias',
  url_relatorio: 'Relatorio',
  url_historico_resenha: 'historicoResenha',
  url_resenha: 'resenha',
  url_pgdi: 'pgdi',
  url_termo: 'termo',
  url_matricula: 'Matricula',
  url_parecer: 'Parecer',
  url_tipo_notificacao: 'TipoNotificacao',
  url_notificacao: 'Notificacao',
  url_configuracao_notificacao: 'TipoConfiguracaoNotificacao',
  url_secretaria_executiva: 'SecretariaExecutiva',
  url_secretaria_geral: 'SecretariaGeral',
  url_gerencia_executiva: 'GerenciaExecutiva',
  url_gerencia_regional: 'GerenciaRegional',
  url_cargo_comissionado: 'TipoCargoComissionadoProfissional',
  url_classificacao_funcional: 'TipoCodigoClassificacaoFuncionalProfissional',
  url_forma_admissao: 'TipoFormaAdmissaoProfissional',
  url_formacao_academica: 'TipoFormacaoProfissional',
  url_grau_instrucao: 'TipoInstrucaoProfissional',
  url_modalidade_ensino: 'TipoEnsinoProfissional',
  url_motivo_saida: 'TipoMotivoSaidaProfissional',
  url_situacao_funcional: 'TipoSituacaoFuncionalProfissional',
  url_tipo_aposentadoria: 'TipoAposentadoriaProfissional',
  url_importacao_profissionais: 'Importacao',
  url_exclusao_registro: 'ExclusaoRegistro',
  url_cargo: 'Cargo',
  url_regime_contratacao: 'RegimeContratacao',
  url_Subgerencia: 'Subgerencia',
  url_tipo_cargo_comissionado: 'TipoCargoComissionado',
  url_ano_letivo: 'AnoLetivo',
  url_escola: 'escola',
  url_nucleo: 'Nucleo'
});

export const URL_QUADRO_FUNCIONAL = Object.freeze({
  url_gre: 'gre',
  url_espelho: 'espelho',
  url_corpo_diretivo_detalhe: 'corpo-diretivo-detalhe',
  url_demais_profissionais_detalhe: 'demais-profissionais-detalhe',
  url_professor_detalhe: 'professor-detalhe',
  url_professor: 'professor',
  url_alocacao: 'alocacao',
  url_demais_profissionais: 'demais-profissionais',
  url_corpo_diretivo: 'corpo-diretivo',
  url_componente_curricular: 'componente-curricular',
  url_disponivel: 'disponivel',
  url_disponivel_modal: 'disponivel-modal',
  url_professores_modal_lotacao_coordenacao:'escola/alocacao-coordenacao-pedagogica/professor-disponivel',
});
