import { AfterViewInit, Directive, ElementRef, OnInit, ViewChildren } from '@angular/core';
import { AbstractControl, FormControlName, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { NgxPermissionsService } from 'ngx-permissions';
import { fromEvent, merge, Observable } from 'rxjs';

import { ButtonSubmit } from '@core/models/button-submit.model';
import { ValidateMessage } from '@core/models/validate-message.model';
import { Util } from '@shared/utils/util';
import { GenericValidatorForm } from './generic-validator-form';
import { ValidationMessages } from './validation-messages';
import { ValidatorsMessage } from './validatorsMessage';

import { CrudActionType } from '@core/enums/crud-action-enum';
import { SubSink } from 'subsink';
import { MSG_CAMPOS_OBRIGATORIOS_SEAD } from './constants';
import { SweetalertCustom } from './sweetalert-custom';

@Directive()
// tslint:disable-next-line: directive-class-suffix
export class FormBase implements OnInit, AfterViewInit {
  @ViewChildren(FormControlName, { read: ElementRef })
  public formInputElements: ElementRef[];

  public nameScreen = '';
  public pageId = '';
  public typePage = '';
  public sub = new SubSink();
  public order: boolean = false;
  public collumnName: string;

  // Utilizados para validação e submição
  public validateMessage = new ValidateMessage();
  public buttonSubmit = new ButtonSubmit();
  public form: FormGroup;
  public validatorsMessage = new ValidatorsMessage();
  private permissionUser;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public validateMessages?: any,
    public permissionsService?: NgxPermissionsService,
    public validateMessagesLocal?: any
  ) {
    this.getParamsScreen();
    if (!this.validateMessagesLocal) {
      this.validateMessagesLocal = {};
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.controlsBlurValidate();
    this.createValidateFields();
  }

  public setPermissionInComponent(
    permissions: string[],
    permissionUser: string,
    redirect = false
  ) {
    this.permissionUser = permissionUser;
    const existList = permissions.find((x) => x.includes(permissionUser));

    if (!existList && redirect) {
      return this.router.navigate(['/sem-permissao']);
    }

    this.permissionsService.loadPermissions(permissions);
  }

  public hiddenComponenteWithAll(permissions: string[]) {
    const existList = permissions.find((x) => x.includes(this.permissionUser));

    if (!existList) {
      return false;
    }

    return true;
  }

  /**
   * Função que obtem a ação a ser executada
   */
  public getParamsScreen() {
    this.pageId = this.activatedRoute.snapshot.params.id;
    this.nameScreen = Util.getScreenName(this.pageId);
  }

  /**
   * Função que realiza a validação por Blur
   */
  public controlsBlurValidate() {
    const controlBlurs: Observable<any>[] = this.formInputElements.map(
      (formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur')
    );
    merge(...controlBlurs).subscribe((value) => {
      this.validateMessage.messageDisplay =
        this.validateMessage.genericValidator.processMessages(this.form);
    });
  }

  /**
   * Seta a classe de erro no campo
   * @param field Campo a ser realizado a tratativa
   */
  public setErrorValidate(field) {
    return Util.setErrorsValidate(
      this.form,
      this.validateMessage.messageDisplay,
      field
    );
  }

  /**
   * Função que habilita/desabilita o botão de salvar
   * verificando ser o form é valido
   */
  public enableShipping() {
    if (this.form.valid && !this.buttonSubmit.buttonSubmited) {
      return false;
    }
    return true;
  }

  /**
   * Utilizados no autocomplete
   */
  public updateErrors() {
    setTimeout(() => {
      this.validateMessage.messageDisplay =
        this.validateMessage.genericValidator.processMessages(this.form);
    }, 100);
  }

  /**
   * Mensagens utilizadas na validação
   */
  public createValidateFields() {
    const globalValidateMessages = new ValidationMessages().getMessages();
    this.validateMessage.validationMessages = this.validateMessages
      ? { ...globalValidateMessages, ...this.validateMessages }
      : globalValidateMessages;

    this.validateMessage.genericValidator = new GenericValidatorForm(
      this.validateMessage.validationMessages
    );
  }

  public choosePageUpdateOrView(): void {
    this.typePage = this.activatedRoute.snapshot.paramMap.get('type');
    if (this.typePage === 'visualizar') {
      this.form.disable();
    }
  }

  get botaoViewFilter(): boolean {
    return this.router.url.includes('visualizar');
  }

  get isView() {
    return this.router.url.includes('visualizar');
  }

  get isEdit() {
    return this.router.url.includes('editar');
  }

  public isRequired(controlName: string, formGroup = this.form) {
    const control = formGroup.get(controlName);
    return control?.validator && control.validator({} as AbstractControl) &&  control.validator({} as AbstractControl)?.hasOwnProperty('required');
  }

  // Função que força o processamento das mensagens vindo do input child
   forceProcessMessages() {
    this.validateMessage.messageDisplay =
      this.validateMessage.genericValidator.processMessages(this.form);
  }

  public obterTipoSubmissao(): CrudActionType {
    if (Util.validateAllFormFields(this.form)) {
      const actionCrud = this.pageId ? CrudActionType.ATUALIZAR : CrudActionType.CADASTRAR;
      return actionCrud;
    }

    SweetalertCustom.newShowAlertConfirm('warning', 'Atenção!', MSG_CAMPOS_OBRIGATORIOS_SEAD, 'Ok')
    .then((res) => {
      if (res.isConfirmed) {
        this.forceProcessMessages();
        return CrudActionType.NADA;
      }
    });
  }

  public CamposDePesquisaEstaoVazios(): boolean {
    const controls = Object.values(this.form.controls);
    const camposEstaoVazios = controls.every(control => !control.value || control.value === '');

    return camposEstaoVazios;
  }
}
