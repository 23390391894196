import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPaginationModule } from 'ngx-pagination';
import { AutoCompleteModule, BreadcrumbModule, CalendarModule, CheckboxModule, MultiSelectModule, PaginatorModule, RadioButtonModule, TabViewModule } from 'primeng';
import { CardComponent } from './card/card.component';
import { AlertCustomComponent } from './components/alert-custom/alert-custom.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { CardMenuComponent } from './components/card-menu/card-menu.component';
import { CardsQuantitativosComponent } from './components/cards-quantitativos/cards-quantitativos.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DragAndDropComponent } from './components/drag-and-drop/drag-and-drop.component';
import { FieldComponent } from './components/field/field.component';
import { FilterComponent } from './components/filter/filter.component';
import { AdvancedPieChartComponent } from './components/generic-charts/advanced-pie-chart/advanced-pie-chart.component';
import { FooterStackedChartComponent } from './components/generic-charts/footer-stacked-char/footer-stacked-chart.component';
import { NormalizedHorizontalBarChartComponent } from './components/generic-charts/normalized-horizontal-bar-chart/normalized-horizontal-bar-chart.component';
import { PieChartComponent } from './components/generic-charts/pie-chart/pie-chart.component';
import { GenericFormComponent } from './components/generics-crud-components/generic-form/generic-form.component';
import { CustomColumnsDirective } from './components/generics-crud-components/generic-list/directives/custom-columns.directive';
import { GenericListComponent } from './components/generics-crud-components/generic-list/generic-list.component';
import { LoadingCustomInterceptor } from './components/loading-custom/config/loading-custom-interceptor';
import { LoadingCustomComponent } from './components/loading-custom/loading-custom.component';
import { ModalAvisoPadraoComponent } from './components/modal-aviso-padrao/modal-aviso-padrao.component';
import { ModalRedigiteSenhaIncorretaComponent } from './components/modal-redigite-senha/modal-redigite-senha-incorreta/modal-redigite-senha-incorreta.component';
import { ModalRedigiteSenhaComponent } from './components/modal-redigite-senha/modal-redigite-senha.component';
import { PageTitleCustomComponent } from './components/page-title-custom/page-title-custom.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { PaginatorCustomComponent } from './components/paginator-custom/paginator-custom.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { QuestionnaireComponent } from './components/questionnaire/questionnaire.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { TableBodyDirective } from './components/table-custom/config/table-body.directive';
import { TableEmptyDirective } from './components/table-custom/config/table-empty.directive';
import { TableHeaderDirective } from './components/table-custom/config/table-header.directive';
import { TableCustomComponent } from './components/table-custom/table-custom.component';
import { TimelineHorizontalComponent } from './components/timeline-horizontal/timeline-horizontal.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { UploadLargeFileComponent } from './components/upload-large-file/upload-large-file.component';
import { UploadOneFileComponent } from './components/upload-one-file/upload-one-file.component';
import { UploadPhotoComponent } from './components/upload-photo/upload-photo.component';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { UpperCaseDirective } from './directives/upper-case.directive';
import { CPFPipe } from './pipes/cpf.pipe';
import { StatusPipe } from './pipes/status.pipe';
import { UploadOneFileV2Component } from './components/upload-one-file-v2/upload-one-file-v2.component';


@NgModule({
  declarations: [
    AlertCustomComponent,
    LoadingCustomComponent,
    PaginatorCustomComponent,
    TableCustomComponent,
    TableHeaderDirective,
    TableBodyDirective,
    TableEmptyDirective,
    UpperCaseDirective,
    OnlyNumberDirective,
    CardComponent,
    UploadOneFileComponent,
    PageTitleComponent,
    SearchInputComponent,
    FilterComponent,
    ToggleSwitchComponent,
    StatusPipe,
    AutocompleteComponent,
    DatePickerComponent,
    UploadPhotoComponent,
    CPFPipe,
    FieldComponent,
    DragAndDropComponent,
    GenericListComponent,
    GenericFormComponent,
    CustomColumnsDirective,
    PieChartComponent,
    AdvancedPieChartComponent,
    FooterStackedChartComponent,
    NormalizedHorizontalBarChartComponent,
    UploadLargeFileComponent,
    CardMenuComponent,
    QuestionnaireComponent,
    CardsQuantitativosComponent,
    TimelineHorizontalComponent,
    ProgressBarComponent,
    ModalAvisoPadraoComponent,
    PageTitleCustomComponent,
    ModalRedigiteSenhaComponent,
    ModalRedigiteSenhaIncorretaComponent,
    UploadOneFileV2Component
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxPaginationModule,
    BreadcrumbModule,
    NgxMaskModule.forRoot(),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBorderRadius: '0px',
      fullScreenBackdrop: true,
      backdropBackgroundColour: 'rgba(0,0,0,0.3)',
      primaryColour: '#007bff',
      secondaryColour: '#0069d9',
      tertiaryColour: '#007bff',
    }),
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    AutoCompleteModule,
    NgxChartsModule,
    DragDropModule,
    CalendarModule,
    TabViewModule,
    PaginatorModule
  ],
  exports: [
    AlertCustomComponent,
    LoadingCustomComponent,
    PaginatorCustomComponent,
    TableCustomComponent,
    TableHeaderDirective,
    TableBodyDirective,
    TableEmptyDirective,
    UpperCaseDirective,
    OnlyNumberDirective,
    CardComponent,
    UploadOneFileComponent,
    PageTitleComponent,
    SearchInputComponent,
    FilterComponent,
    MultiSelectModule,
    ToggleSwitchComponent,
    CheckboxModule,
    StatusPipe,
    AutocompleteComponent,
    DatePickerComponent,
    UploadPhotoComponent,
    DatePickerComponent,
    RadioButtonModule,
    DragAndDropComponent,
    CPFPipe,
    FieldComponent,
    GenericListComponent,
    GenericFormComponent,
    CustomColumnsDirective,
    PieChartComponent,
    AdvancedPieChartComponent,
    FooterStackedChartComponent,
    NormalizedHorizontalBarChartComponent,
    UploadLargeFileComponent,
    CardMenuComponent,
    QuestionnaireComponent,
    CardsQuantitativosComponent,
    TimelineHorizontalComponent,
    ProgressBarComponent,
    PageTitleCustomComponent,
    UploadOneFileV2Component
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingCustomInterceptor,
      multi: true,
    },
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
